import menuCardStyleMixin from '@/utils/mixins/styling/menuCardStyle.mixin.js'
import { mapState, mapGetters, mapMutations } from 'vuex'

export const layerControlMixin = {
  mixins: [menuCardStyleMixin],
  computed: {
    ...mapState({
      dataPending: state => state.mapbox.dataPending,
      layersPending: state => state.mapbox.layersPending,
      selectedLayers: state => state.mapbox.selectedLayerOptions,
      selectedLayerTPPs: state => state.mapbox.selectedLayerTppOptions,
      selectedLayerCables: state => state.mapbox.selectedLayerCableOptions
    }),
    ...mapGetters({
      // Layer options
      layerOptions: 'mapbox/layerOptions',
      initialLayerOptions: 'mapbox/initialLayers',
      // Layer TPP options
      layerTppOptions: 'mapbox/layerTppOptions',
      initialLayerTppOptions: 'mapbox/initialLayerTpps',
      // Layer Cable options
      layerCableOptions: 'mapbox/layerCableOptions',
      initialLayerCableOptions: 'mapbox/initialLayerCables'
    })
  },
  methods: {
    ...mapMutations({
      setSelectedLayers: 'mapbox/setSelectedLayerOptions',
      setSelectedLayerTPPs: 'mapbox/setSelectedLayerTppOptions',
      setSelectedLayerCables: 'mapbox/setSelectedLayerCableOptions'
    })
  }
}
