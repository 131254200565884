<template>
  <div class="mapbox-gl-control layer-control" :style="[offsetStyle, posStyle]">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      :left="attachLeft"
      offset-x
      z-index="10"
    >
      <!-- Button -->
      <template #activator="{ on: menu }">
        <v-tooltip :left="attachLeft" :right="!attachLeft">
          <template #activator="{ on: tooltip }">
            <v-btn
              class="pa-1 ma-2"
              height="auto"
              min-width="0"
              :disabled="!allowedActive"
              :color="!selectedNone ? 'primary darken-1' : 'white'"
              small
              v-on="{ ...tooltip, ...menu }"
              @click="initLabels"
            >
              <v-icon>layers</v-icon>
            </v-btn>
          </template>
          <span>
            <slot name="tooltip">Layers</slot>
          </span>
        </v-tooltip>
      </template>

      <!-- Content -->
      <v-card outlined flat>
        <v-card-text>
          <slot />
          <v-layout :style="layoutStyle" column>
            <!-- Layers select -->
            <pibot-mapbox-layer-select
              v-if="layerOptions.length"
              :value="selectedLayers"
              :items="layerOptions"
              @change="updateSelectedLayers"
              @toggle="toggleLayers"
            />

            <v-divider class="my-3" v-if="layerOptions.length > 0 && layerTppOptions.length > 0" />

            <!-- Layer TPPs select -->
            <pibot-mapbox-layer-select
              v-if="layerTppOptions.length"
              :value="selectedLayerTPPs"
              :items="layerTppOptions"
              @change="updateSelectedLayerTPPs"
              @toggle="toggleLayerTPPs"
              label="Third Party Pipelines (Rijkswaterstaat)"
            />

            <v-divider class="my-3" v-if="layerTppOptions.length > 0 && layerCableOptions.length > 0" />

            <!-- Layer Cables select -->
            <pibot-mapbox-layer-select
              v-if="layerCableOptions.length"
              :value="selectedLayerCables"
              :items="layerCableOptions"
              @change="updateSelectedLayerCables"
              @toggle="toggleLayerCables"
              label="Cables in the Northsea (Rijkswaterstaat)"
            />
          </v-layout>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import menuCardStyleMixin from '@/utils/mixins/styling/menuCardStyle.mixin.js'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import mapboxLazyMixin from '@/utils/mixins/mapbox/mapboxLazy.mixin.js'
import { layerControlMixin } from '../utils/mixins/layerControl.mixin.js'

console.error('Remove duplicate code')
console.error('selectedAll/Some/None functions should check in alltypes of asset selectors')

export default {
  name: 'pibot-mapbox-layer-control',
  mixins: [
    positionStyleMixin,
    offsetStyleMixin,
    menuCardStyleMixin,
    mapboxBasicsMixin,
    mapboxLazyMixin,
    layerControlMixin
  ],
  components: {
    'pibot-mapbox-layer-select': () => import('./Select')
  },
  data () {
    return {
      initialized: false,
      menu: false,
      labelsInitialized: false,
      unwatchLayers: null
    }
  },
  computed: {
    selectedNone () {
      return this.selectedLayers.length === 0 && this.selectedLayerTPPs.length === 0 && this.selectedLayerCables.length === 0
    },
    allowedActive () {
      return (this.layerOptions.length || this.layerTppOptions.length || this.layerCableOptions.length)
    }
  },
  methods: {
    /**
     * Initialize the spacing of the labels on the v-autocomplete components which
     * have the 'outlined' styling.
     * This is done by emptying the value and setting the value back to it's
     * original value after the component has become visible.
     * This causes the label to recalculate it's width.
     */
    initLabels () {
      if (this.labelsInitialized) return // No need to do it again
      if (!this.selectedLayers.length && !this.selectedLayerTPPs.length) return // It is already empty

      // Save the original values
      const originLayers = JSON.parse(JSON.stringify(this.selectedLayers))
      const originLayerTPPs = JSON.parse(JSON.stringify(this.selectedLayerTPPs))
      // Empty the current values
      this.setSelectedLayers([])
      this.setSelectedLayerTPPs([])

      // Restore the original values after the component has become visible
      setTimeout(() => {
        this.setSelectedLayers(originLayers)
        this.setSelectedLayerTPPs(originLayerTPPs)
        this.labelsInitialized = true
      }, 280) // Menu animation takes 280ms to complete
    },
    // ----------------------------------------------------- Layers -----------------------------------------------------
    updateSelectedLayers (selectedLayers) {
      this.setSelectedLayers(selectedLayers)
      for (const option of this.layerOptions) {
        const willBeVisible = this.selectedLayers.includes(option.value)

        if (!willBeVisible) {
          // No need to lazy-load, continue as normal
          this.setLayerVisibility(option.value, willBeVisible)
          continue
        }

        // Handle lazy-loading
        this.lazyLoadSource(option.value)
          .then(() => {
            this.setLayerVisibility(option.value, willBeVisible)
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    toggleLayers () {
      this.$nextTick(() => {
        let newValue

        if (this.selectedLayers.length === this.layerOptions.length) newValue = []
        else newValue = this.layerOptions.map(o => o.value)
        this.updateSelectedLayers(newValue)
      })
    },
    // ----------------------------------------------------- Layers TPPs -----------------------------------------------------
    updateSelectedLayerTPPs (selectedLayerTPPs) {
      this.setSelectedLayerTPPs(selectedLayerTPPs)
      for (const option of this.layerTppOptions) {
        const willBeVisible = this.selectedLayerTPPs.includes(option.value)

        if (!willBeVisible) {
          // No need to lazy-load, continue as normal
          this.setLayerVisibility(option.value, willBeVisible)
          continue
        }

        // Handle lazy-loading
        this.lazyLoadSource(option.value)
          .then(() => {
            this.setLayerVisibility(option.value, willBeVisible)
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    toggleLayerTPPs () {
      this.$nextTick(() => {
        let newValue

        if (this.selectedLayerTPPs.length === this.layerTppOptions.length) newValue = []
        else newValue = this.layerTppOptions.map(o => o.value)
        this.updateSelectedLayerTPPs(newValue)
      })
    },
    // ----------------------------------------------------- Layers Cables -----------------------------------------------------
    updateSelectedLayerCables (selectedLayerCables) {
      this.setSelectedLayerCables(selectedLayerCables)
      for (const option of this.layerCableOptions) {
        const willBeVisible = this.selectedLayerCables.includes(option.value)

        if (!willBeVisible) {
          // No need to lazy-load, continue as normal
          this.setLayerVisibility(option.value, willBeVisible)
          continue
        }

        // Handle lazy-loading
        this.lazyLoadSource(option.value)
          .then(() => {
            this.setLayerVisibility(option.value, willBeVisible)
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    toggleLayerCables () {
      this.$nextTick(() => {
        let newValue

        if (this.selectedLayerCables.length === this.layerCableOptions.length) newValue = []
        else newValue = this.layerCableOptions.map(o => o.value)
        this.updateSelectedLayerCables(newValue)
      })
    }
  },
  created () {
    /**
     * Set initially 'visible' layerOptions as selectedLayers on init
     * @see https://vuejs.org/v2/api/#vm-watch
     * @see https://stackoverflow.com/questions/46987893/vuejs2-how-can-i-destroy-a-watcher#answer-46988117
     */
    this.unwatchLayers = this.$watch(
      'layersPending',
      layersPending => {
        if (!this.initialized && !layersPending) {
          this.setSelectedLayers(this.initialLayerOptions)
          this.setSelectedLayerTPPs(this.initialLayerTppOptions)
          this.setSelectedLayerCables(this.initialLayerCableOptions)
          this.initialized = true

          // Unwatch after init
          if (this.unwatchLayers) this.unwatchLayers()
          else console.error("unable to 'unwatch' 'layersPending'")
        }
      },
      {
        deep: true
      }
    )
  }
}
</script>
